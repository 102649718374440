body{
    margin: 0;
    padding: 0;
    color: #ddd;
    font-family: Arial;
    background-color: $bg;
}

h1{
    color: #fff;
}
